import React from "react";
import { OutboundLink } from "gatsby-plugin-google-analytics";
import { FaGlobe } from "@react-icons/all-files/fa/FaGlobe";

import Head from "../../components/head.js";

import Layout from "../../components/layout";

const Mari = () => {
  return (
    <Layout>
      <Head title="Mari K" />
      <h3>Mari K</h3>
      <h4>
        <FaGlobe style={{ marginRight: 7, paddingTop: 5 }} />
        Istanbul, Turkey
      </h4>
      <p>
        <OutboundLink href="https://madmaraca.art/">Website</OutboundLink>
      </p>
      <p>
        <OutboundLink href="https://lynkfire.com/MadMaraca">
          Lynkfire
        </OutboundLink>
      </p>
      <p>
        Mari.K best known as "MadMaraca" is a 3D designer, Voxel artist and
        Metaverse Architect with over a decade experience in digital art. Her
        main expertise is voxel art ( which is essentially 3D pixel art). Mari
        creates micro worlds / dioramas with intricate details and through her
        work she combines architecture, culture and nature by creating unseen
        fantasy worlds for her audience to explore and discover.
      </p>
      <p>
        Mari's work has been featured in many exhibitions both IRL and in the
        metaverse, such as the "Influences" show, a physical/Virtual art show in
        Brooklyn New York. "DART 2121" Milan Italy, "Crypto Art Fair" Abu Dhabi
        international expo- UAE, and also in the Metaverse, as a part of
        SuperRare's first virtual exhibition "Invisible Cities", a sci-fi
        exhibition "ARC NOVO" and several other virtual events and exhibitions.
      </p>
      <p>
        Mari has built a few virtual galleries in the metaverse and has curated
        a number of exhibitions featuring NFT artists from around the world.
      </p>
      <img
        src="https://pbs.twimg.com/media/FaWA7ypWAAANJko?format=jpg&name=4096x4096"
        alt="Mari K art"
      />
      <img
        src="https://cdnb.artstation.com/p/marketplace/printed_product_covers/000/101/099/art_print_big/file.jpg?1637397839"
        alt="Mari K art"
      />
      <img
        src="https://pbs.twimg.com/media/FaBa02yWIAAKxD1?format=jpg&name=4096x4096"
        alt="Mari K art"
      />
    </Layout>
  );
};

export default Mari;
